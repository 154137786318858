<template>
  <div class="container-fluid mt-5">
    <div class="user-edit">
      <div slot="header" class="user-edit-header">
        <h3 class="mb-0">{{ $t("COMMON.MY_ORGANIZATION") }}</h3>
        <base-button @click="goBack" type="button" class="btn btn-sm">
          <i class="fal fa-arrow-left"></i>
          {{ $t("COMMON.GO_BACK") }}
        </base-button>
      </div>
      <organization-form
        v-if="organization"
        :loading="loading"
        :organizationData="organization"
        :formErrors="formErrors"
        @organizationSubmitted="handleSubmit"
        @formChanged="() => (alertLeave = true)"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import OrganizationForm from "@/views/Pages/AdminModule/OrganizationManagement/partials/OrganizationForm.vue";
import { mapGetters } from "vuex";
import swal from "sweetalert2";

export default {
  layout: "DashboardLayout",

  components: { OrganizationForm },

  mixins: [alertLeave, requestErrorMixin],

  data() {
    return {
      organization: null,
      formErrors: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({ me: "profile/me" }),
  },

  async created() {
    swal.showLoading();
    await this.$store.dispatch("profile/me");
    await this.get();
  },

  methods: {
    async get() {
      try {
        if (!this.$idExist(this.me.organization?.id)) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_ORGANIZATION"),
          });
          this.$router.replace("/");
          return;
        }

        await this.$store.dispatch(
          "organizations/get",
          this.me.organization.id
        );

        this.organization = this.$store.getters["organizations/organization"];
        swal.close();
      } catch (error) {
        swal.close();
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "View Organization" });
    },

    async handleSubmit(organization) {
      this.loading = true;

      const organizationData = cloneDeep(organization);

      try {
        await this.$store.dispatch("organizations/update", organizationData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ORGANIZATIONS.ORGANIZATION_UPDATED"),
        });
        this.viewOrganization();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    viewOrganization() {
      this.alertLeave = false;
      this.$router.push({
        name: "View Organization",
      });
    },
  },
};
</script>
